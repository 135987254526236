import React from "react";
import { useLocation, Link } from "react-router-dom";
import Util from "../util";
const PaymentComponent = (props) => { 
    const location = useLocation();
    const json = location.state.json;
    const [canPlay, setCanPlay] = React.useState('');
    const [buyComplete, setBuyComplete] = React.useState('');
    const [time, setTime] = React.useState('');

    const getStatusRent = () => {
      if(json.ppv!="pay") {
        Util.get(Util.API+"status-rent/?idVideo="+json.id+"&email="+localStorage.getItem('email'))
        .then(async (response) => {
            if(response.data==1) {
              setBuyComplete('true');
              return;
            }
            if(response.data==0) {
              setBuyComplete('false');
              return;
            }
            if(response.data==-1) {
              setBuyComplete('');
            }
            await new Promise(r => setTimeout(r, 2000));
            getStatusRent();
            return;
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        Util.get(Util.API+"status-buy/?idVideo="+json.id+"&email="+localStorage.getItem('email'))
        .then(async (response) => {
          console.log(response.data);
            if(response.data==1) {
              setBuyComplete('true');
              return;
            }
            if(response.data==0) {
              setBuyComplete('');
              return;
            }
            if(response.data==-1) {
              setBuyComplete('');
            }
            await new Promise(r => setTimeout(r, 2000));
            getStatusRent();
            return;
          })
          .catch((error) => {
            console.log(error);
          });
      }     
    }

    React.useEffect(() => {      
      window.scrollTo(0, 0);
      if(json.ppv!="pay") {
      Util.get(Util.API+"time/")
        .then((response) => {
            setTime(response.data);
            if(parseInt(json.datePreRent)>0) {
              let timeNow = parseInt(response.data)*1000;
              if(timeNow>=parseInt(json.datePreRent)*1000) {
                  setCanPlay('true');
              } else {
                  setCanPlay('false');
              }
          }
          else {
              setCanPlay('true');
          }  

          })
          .catch((error) => {
            console.log(error);
          });
        } else {
          setCanPlay('true');
        }
          async function check() {
            await new Promise(r => setTimeout(r, 2000));
            getStatusRent();
          }

          check();
          

      }, [location.key]);


    
  return (
    canPlay!=='' && 
    <div>
      
      <div className="play-thumb mb-4 text-center rounded">
            <br></br>
            <br></br>
            
            <h5 className="text-danger">{json.title}</h5>
            <p className="">{json.subtitle}</p>
            
            {
              canPlay==='true' ? ""
              : <>
              <p className="m-0">El video estará disponible: <h5 className="text-danger">{Util.seconsToDate(json.datePreRent*1000)}</h5> </p>
              <p>En Pre-Alquiler a partir de esa fecha dispondrá de 48 horas para verlo</p>
            </>
            }
            <Link
            to="/movie-single"
            >
              <img className="image-payment" src={json.imageTitle}></img>
            <img
                        className="img-fluid img-thumb rounded"
                        src={ json.imageThumb}
                        alt=""
                    />
            </Link>
            <br></br>
            <br></br>
            {
              json.ppv=="pay"
              ? <h5 className="text-concept"><span className="text-danger"><small>Comprar</small></span>  <small className="text-danger"> por </small>{json.rentPrice/100}€</h5>
              : <h5 className="text-concept"><span className="text-danger"><small>Alquilar</small></span> {json.rentTime} <small className="text-danger">horas por </small>{json.rentPrice/100}€</h5>
  
            }
           </div>
      {buyComplete==='' ? 
      <iframe id="iFrame" className="" 
      src={json.ppv=="pay" 
        ? "https://islandfighters.com/redsys/getPetBuy.php?idVideo="+json.id+"&email="+localStorage.getItem('email')
        : "https://islandfighters.com/redsys/getpet.php?idVideo="+json.id+"&email="+localStorage.getItem('email') }
      style={{border: 'none',  height: '200px', width: '100%'}}
          loading="lazy"
          >            
      </iframe>
      : buyComplete==='true' 
        ? <h5 className="text-center m-5">Compra completada</h5>
        : <h5 className="text-center m-5">No se ha podido completar la operación</h5>
      }
    </div>
    
  );
};

export default PaymentComponent;

